
import moment from "moment";
import moment_tz from "moment-timezone";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import cloneDeep from "lodash.clonedeep";
import { tsGraphQLService } from "@/shared/services/api/trouble-shooting-graphQL";
import { tsService } from "@/shared/services/api/trouble-shooting";
import {
  periodicSubTabHeader,
  SCHEDULE_STATUS,
} from "@/shared/interface/scheduledItem";
import { WORKITEM_JOB_TYPE, SCHEDULE_TYPE } from "@/shared/interface/newItem";
import { EXECUTION_STATUS } from "@/shared/interface/workItemDetails";
import {
  PeriodicalSubTableEntry,
  WORKITEM_FILTER_CRITERIA,
} from "@/shared/interface/workItem";

@Component({
  name: "TableComponent",
  components: {},
})
export default class TableComponent extends Vue {
  @Prop({ default: SCHEDULE_TYPE.once }) scheduledType;
  @Prop({ default: () => periodicSubTabHeader() }) tableHeader;
  @Prop({ default: "" }) scheduleId;
  @Prop({ default: "" }) workItemId;
  @Prop({ default: "" }) createdTimeRangeFrom;
  @Prop({ default: "" }) createdTimeRangeTo;
  @Prop({ default: PeriodicalSubTableEntry.ScheduledWorkItem }) entryPoint;

  loading = false;
  pageSize = 10;
  currentPage = 0;
  offset = 0;
  total = 0;
  tableKey = "work-item-";
  tableData: any = [];
  timezoneSettings = {
    currentTimezone: moment_tz.tz.guess(),
    currentTimezoneOffset: Math.floor(moment().utcOffset() / 60),
  };
  NegExecutionEnums = [
    EXECUTION_STATUS.initialization,
    EXECUTION_STATUS.submitFailed,
    null,
  ];
  executionIds: string[] = []; //current page only store none-null execution_id
  hasCallExecutionStatus = false;
  JOBRUNNING = EXECUTION_STATUS.running;
  cancelBtnObj = {};

  created(): void {
    this.getData();
  }

  judge_btn_visible(executeStatus: string): boolean {
    return this.NegExecutionEnums.includes(executeStatus as EXECUTION_STATUS);
  }

  @Watch("hasCallExecutionStatus")
  queryJobExecution(): void {
    this.tableData.map((it) => {
      if (!["NA", null].includes(it.execution_id)) {
        this.executionIds.push(it.execution_id);
      }
    });

    if (this.hasCallExecutionStatus && this.executionIds.length > 0) {
      this.setExecutionStatus();
    }
  }

  setExecutionStatus(): void {
    this.loading = true;

    tsGraphQLService
      .getExecutionByExecutionIds({ ExecutionIds: this.executionIds })
      .then(
        (res) => {
          const data = res.data.cx_iops_agent_job_execution;

          const proxyData = cloneDeep(this.tableData);
          proxyData.map((it, idx) => {
            if (!["NA", null].includes(it.execution_id)) {
              this.$set(proxyData, idx, {
                ...proxyData[idx],
                execution_status:
                  data.filter((_it) => it.execution_id === _it.execution_id)[0]
                    ?.execution_status || EXECUTION_STATUS.initialization,
              });
            } else {
              this.$set(proxyData, idx, {
                ...proxyData[idx],
                execution_status:
                  it.execution_id === "NA"
                    ? EXECUTION_STATUS.submitFailed
                    : EXECUTION_STATUS.initialization,
              });
            }
          });

          this.tableData = proxyData;
        },
        (error) => {
          const status = error?.networkError?.statusCode;
          if (status === 401) {
            location.reload();
          }
        }
      )
      .finally(() => {
        this.executionIds = [];
        this.loading = false;
        this.hasCallExecutionStatus = false;
      });
  }

  /**
   * Skip to the Case Details page
   * @params {id}: case id
   * @params {executionId}: for periodically scheduled job, only joint work_item_id and executionId to define execution table
   * @params {serviceId}: when job is not request rundeck,execution_id hadn't generated yet, use service_id to message topic for rtc logging
   * @returns void
   */
  gotoDetail(executionId = "", serviceId = ""): void {
    let defaultRoute = `/detail?id=${this.workItemId}&jobType=${WORKITEM_JOB_TYPE.scheduled}&scheduledType=${this.scheduledType}&newTab=true`;

    if (executionId) {
      defaultRoute += `&executionId=${executionId}`;
    }

    if (serviceId) {
      defaultRoute += `&serviceId=${serviceId}`;
    }

    const route = this.$router.resolve({ path: defaultRoute });
    window.open(route.href);

    // this["$router"].push(defaultRoute)
  }

  /**
   * Retrieve data based on the current page number
   * @params {currentPage}: current page
   * @returns void
   */
  currentPageChange(currentPage: number): void {
    this.currentPage = currentPage;
    this.offset = (currentPage - 1) * this.pageSize;
    this.getData();
  }

  /**
   * get data
   * @returns void
   */
  getData(): void {
    this.loading = true;

    const params: WORKITEM_FILTER_CRITERIA = {
      scheduleId: this.scheduleId,
      offset: this.offset,
      pageSize: this.pageSize,
    };

    if (this.entryPoint === PeriodicalSubTableEntry.ViewWorkItem) {
      params.ScheduledSuccess = SCHEDULE_STATUS.ScheduleSuccess;
    }

    params.createdTimeRangeFrom = this.createdTimeRangeFrom
      ? this.createdTimeRangeFrom
      : null;
    params.createdTimeRangeTo = this.createdTimeRangeTo
      ? this.createdTimeRangeTo
      : null;

    tsGraphQLService
      .getPeriodicalJobs(params)
      .then(
        (res) => {
          let data = res.data;
          if (data?.listByPage?.length > 0) {
            data.listByPage.forEach((item, index) => {
              this.$set(data.listByPage, index, {
                ...data.listByPage[index],
                start_time: item.start_time
                  ? moment(item.start_time).format("YYYY-MM-DD HH:mm:ss")
                  : "",
                created_time: item.start_time
                  ? moment(item.start_time).format("YYYY-MM-DD HH:mm:ss")
                  : "",
                _id: item?.execution_id,
                execution_status: EXECUTION_STATUS.initialization,
              });
            });

            this.tableData = data.listByPage;
          } else {
            this.tableData = [];
          }

          this.total = data?.list?.aggregate?.count || 0;
        },
        (error) => {
          const status = error?.networkError?.statusCode;
          if (status === 401) {
            location.reload();
          }
        }
      )
      .finally(() => {
        this.loading = false;
        this.hasCallExecutionStatus = true;
      });
  }

  /**
   * abort running job with execution_id
   */
  cancelRunningJob(executionId: string): void {
    this.cancelBtnObj = {
      ...this.cancelBtnObj,
      [executionId]: true,
    };
    tsService
      .cancelRunningJob(executionId)
      .then(() => {
        this.$message({
          message: "Canceling the running job started",
          type: "success",
          duration: 3000,
        });
      })
      .finally(() => {
        setTimeout(() => {
          this.getData();
        }, 0);
      });
  }
}
